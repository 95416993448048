import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import config from '../config.json';

class MyTeam extends Component {
  state = { activeMember: 0, teamOn: false, showMore: false, display: true };

  async componentDidMount() {
    this.win = null;
  }

  handleShowMore = () => {
    const showMore = !this.state.showMore;
    this.setState({ showMore });
  };
  goToFowDashboard = () => {
    window.open(config.wdFowUrl);
  };

  goToMyTeamLearning = () => {
    window.open(config.myTeamLearningUrl);
  };

  openEmail = email => {
    window.location.href = 'mailto:' + email;
  };

  setUpMeeting = userPrincipalName => {
    const URL =
      'https://teams.microsoft.com/l/meeting/new?subject=Touchbase%20Meeting&attendees=' +
      userPrincipalName;
    window.open(URL);
  };

  openChat = (email, chattype) => {
    this.win = window.open(
      'https://teams.microsoft.com/l/' + chattype + '/0/0?users=' + email,
      'Chat',
    );
  };

  openMeeting = (email, chattype) => {
    this.win = window.open(
      'https://teams.microsoft.com/l/meeting/new?subject=touchbase%20meeting&attendees=' + email,
      'Meeting',
    );
  };

  openDevPlan = () => {
    window.open('https://www.myworkday.com/nationwide/d/task/2998$11723.htmld');
  };

  getTeam = async () => {
    const { retrieveTeam } = this.props;
    retrieveTeam();
  };

  getFow = async activeMember => {
    const { retrieveFOWData, team } = this.props;
    let refresh = false;
    // console.log("myTeam.getFow activeMember", activeMember);

    if (team && team.members[activeMember] && team.members[activeMember].fow) {
      //The team object exists and the team member exists and has a fow property
      if (team.members[activeMember].fow.error) {
        //the current team members fow has an error
        refresh = true;
      }
    }
    retrieveFOWData(refresh);
    this.setState({ activeMember });
  };

  handleMyTeamClick = event => {
    const teamOn = !this.state.teamOn;
    this.setState({ teamOn });
    event.preventDefault();
  };

  handleLoadMore = (event, teamMax) => {
    this.props.handleChangeMaxTeam(teamMax);
  };

  handleMemberClick = (event, memberId) => {
    //A team member button has been clicked.
    // console.log("myTeam.handleMemberClick memberId", memberId);
    const activeMember = memberId;
    //Setting the activeMember will change the Team Member information displayed
    this.setState({ activeMember });
    this.props.changeTeamDisplay(activeMember);
    this.getFow(memberId);
    //Do not fire the OnFocus event
    // event.preventDefault();
  };

  handleTeamExpand = display => {
    this.props.changeWholeTeamDisplay(display);
    this.setState({ display: !display });
  };

  renderFowBar(value, idPrefix, index) {
    // const pct = parseInt(value) > 98 ? 98 : parseInt(value);
    const pct = parseInt(value);
    return (
      <div style={{ width: '100%' }}>
        <div
          id={idPrefix + 'Completion' + index}
          title={value + '%'}
          className="fowBar"
          style={{ display: 'inline-block' }}
        >
          <div
            id={'display' + idPrefix + 'Completed' + index}
            className="fowOverall"
            title={value + '%'}
            style={
              pct > 0
                ? {
                    borderRadius: '12px 0px 0px 12px',
                    width: `${pct}%`,
                    color: 'white',
                  }
                : { width: '20px', borderRadius: '10px' }
            }
          ></div>
        </div>
        <div style={{ display: 'inline-block', paddingLeft: '8px' }}>
          <span className="fowOverallPct">{value}%</span>
        </div>
      </div>
    );
  }

  renderTeamFowBar(value) {
    const pct = parseInt(value) > 98 ? 98 : parseInt(value);
    const displayPct = parseInt(value * 100) / 100;
    return (
      <div style={{ width: '100%', display: 'block' }}>
        <div className="fowCompletionBarAndPct">
          <div id="MyTeamComplettion" title={value + '%'} className="fowBar">
            <div
              id={'displayMyTeamCompleted'}
              className="fowOverall"
              title={displayPct + '%'}
              style={
                pct > 0
                  ? {
                      borderRadius: '12px 0px 0px 12px',
                      width: `${pct}%`,
                      color: 'white',
                    }
                  : { width: '20px', borderRadius: '10px' }
              }
            ></div>
          </div>
        </div>
        <div className="teamCompletionPct">{displayPct}%</div>
      </div>
    );
  }

  renderLearning(learning) {
    if (learning) {
      //The learning object exists
      //Make sure the learning is sorted by the Assigned Date descending
      learning.sort((a, b) => (a.assignedDate > b.assignedDate ? -1 : 1));
      //Only use the three most recent entries
      const learningArray = learning.slice(0, 3);

      if (learningArray && learningArray.length > 0) {
        //There is a non empty array of learning objects
        return (
          <div className="fowContainer">
            {learningArray.map((item, index) => (
              <div
                key={'requiredLearning' + item.emplid + '_' + index}
                className="teamMemberLearning"
              >
                <div className="teamMemberLearningTitle">{item.title}</div>
                <div className={'teamMemberLearningStatus ' + item.classDisplay}>
                  {item.completed}
                </div>
              </div>
            ))}
          </div>
        );
      } else {
        //There is no learning object
        return (
          <div style={{ fontSize: '14px', color: '#1c57a5' }}>No training has been assigned.</div>
        );
      }
    }
  }

  renderFow(fow, index) {
    if (fow) {
      //The fow object exists
      if (fow.error) {
        //the fox object contains an error
        return (
          <div className="fowContainer">
            <div className="fowText">{fow.fowYear} Future of Work Certificate Completion</div>
            <div className="portletError">
              <div className="portletErrorAlert">!</div>
              <div
                style={{
                  fontFamily: 'nw-primary',
                  fontSize: '20px',
                  lineHeight: '24px',
                  color: '#4D4F53',
                }}
              >
                Oops, this is unexpected.
              </div>
              <div
                style={{
                  fontFamily: 'nw-primary',
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#4D4F53',
                }}
              >
                Error code: 500
                <br />
                An error has occurred and we're working to fix the issue.
                <br />
                If the issue persists please{' '}
                <a
                  style={{ color: '#4D4F53', textDecoration: 'underline' }}
                  href={config.contactUsUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  contact us.
                </a>
              </div>
              <div
                className="tile-apierror-refresh"
                // onClick={index => this.getFow(index)}
                onClick={() => this.getFow(index)}
                title="Refresh"
              >
                Refresh
              </div>
            </div>
          </div>
        );
      } else {
        //The fox object does not have an error
        return (
          <div className="fowContainer">
            <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#4D4F53' }}>
            {fow.fowYear} Future of Work Certificate Completion
            </div>
            <div className="fowText">FOW TECHNOLOGY LEARNING</div>
            {this.renderFowBar(fow.digitalCompletion, 'Digital', index)}

            <div className="fowText">FOW LEAD / INNOVATE / ADAPT LEARNING</div>
            {this.renderFowBar(fow.leadCompletion, 'Lead', index)}
          </div>
        );
      }
    } else {
      //The fow object does not exist
      return <React.Fragment></React.Fragment>;
    }
  }

  renderTeamProgress() {
    const { team } = this.props;
    const { teamOn } = this.state;
    const today = new Date();
    const year = today.getFullYear();

    if (team) {
      //the team object esists
      const overall = team.teamOverall.overalFow;
      const allTraining = team.teamOverall.training;
      const trainingCount = allTraining.length;
      let training = [];

      if (this.state.showMore) {
        training = team.teamOverall.training;
      } else {
        training = team.teamOverall.training.slice(0, 3);
      }

      let completed = [];
      let completedIdx = 0;
      for (let i = 0; i < training.length; i++) {
        if (training[i].incomplete === 0 && training[i].complete !== 0) {
          completed[completedIdx++] = { title: training[i].title };
        }
      }
      if (completedIdx === 0) {
        //No completed trainings were found
        completed = null;
      }

      return (
        <React.Fragment>
          <div>
            <div
              style={
                teamOn
                  ? {
                      width: '95%',
                      textAlign: 'left',
                      // borderBottom: "black solid 1px",
                      paddingLeft: '25px',
                      backgroundImage: 'url(/activeTeam.png)',
                    }
                  : {
                      width: '95%',
                      textAlign: 'left',
                      // borderBottom: "black solid 1px",
                      paddingLeft: '25px',
                    }
              }
            >
              <div style={{ borderBottom: '#BCBDBC solid 1px' }}>
                <div style={{ width: '100%' }}>
                  <button
                    tabIndex="0"
                    onClick={e => this.handleMyTeamClick(e)}
                    id={'MyTeamOverview'}
                    style={{
                      // width: "100%",
                      textAlign: 'left',
                      border: 'none',
                      backgroundColor: 'white',
                    }}
                  >
                    <div className="teamMemberChevronContainer">
                      <img
                        src={teamOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                        alt={this.state.teamOn ? "Click to Collapse My Team's Progress Overview" : "Click to expand My Team's Progress Overview"}
                      ></img>
                    </div>
                  </button>
                  <div className="teamMemberImageContainer">
                    <img className="teamMemberImage" alt="" src="/team-progress.svg" />
                  </div>
                  <div className="teamMemberData">
                    My Team's Progress Overview
                    <div className="teamMemberPosition">
                      Review the team's progress, alerts, and more.
                    </div>
                  </div>
                </div>

                <div
                  className="teamDisplay"
                  style={
                    teamOn
                      ? {
                          display: 'block',
                        }
                      : { display: 'none' }
                  }
                >
                  <div style={{ borderTop: 'grey dashed 1px', marginTop: '10px' }}>
                    <div className="teamGoalProgress">
                      My Team's FOW Goal Progress (% completed)
                    </div>
                    {this.renderTeamFowBar(overall)}
                    <div
                      className="teamFowMessage"
                      style={{
                        color: 'rgb(28, 87, 165)',
                        fontSize: '14px',
                      }}
                    >
                      Enterprise FOW completion is due December 31, {year}
                    </div>
                  </div>
                  {/* <div className="blueLinkInternalContainer">
                    <button
                      className="tileButton fowDashboardButton"
                      onClick={() => this.goToFowDashboard()}
                    >
                      FOW Dashboard
                    </button>
                  </div> */}
                  <div style={{ borderTop: 'grey dashed 1px', marginTop: '10px' }}>
                    <div className="teamGoalProgress">
                      My Team's Enterprise Required Learning ({trainingCount})
                    </div>
                    {/*<div>
                    Enterprise Required Learning is currently unavailable. We are working to resolve the issue. In the meantime, please use the following link to find this information.  
                    <div><a href='https://nwproduction.service-now.com/kb_view.do?sysparm_article=KM348464&sysparm_use_polaris=false#'>Click here to go to Required Learning</a></div>
                    </div>*/}
                    {completed ? (
                      <div className="teamCongratulations">
                        <div className="teamCongratulationsHeading">RECENT TEAM COMPLETION</div>
                        {completed.map((item, index) => (
                          <div
                            className="teamCongratulationsItem"
                            key={'teamCongratulationsItem_' + index}
                          >
                            {item.title}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div></div>
                    )}

                    {training && training.length > 0 ? (
                      <React.Fragment>
                        <div className="teamLearningRow">
                          <div className="teamLearningColumn1">&nbsp;</div>
                          <div className="teamLearningColumnOther" style={{ fontSize: '11px' }}>
                            COMPLETE
                          </div>
                          <div className="teamLearningColumnOther" style={{ fontSize: '11px' }}>
                            INCOMPLETE
                          </div>
                        </div>
                        {training.map((item, index) => (
                          <div
                            key={'teamLearingRow_' + index}
                            className="teamLearningRow"
                            style={{ borderTop: 'grey solid 1px' }}
                          >
                            <div
                              className="teamLearningColumn1"
                              style={{ fontSize: '13px', color: '#4D4F53' }}
                            >
                              {item.title}
                            </div>
                            <div className="teamLearningColumnOther" style={{ fontSize: '14px' }}>
                              {item.complete > 0 ? (
                                <div title={item.completedBy} style={{ zIndex: '1' }}>
                                  <img src="/completedTraining.png" alt=""></img> {item.complete}
                                </div>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </div>
                            <div className="teamLearningColumnOther" style={{ fontSize: '14px' }}>
                              {item.incomplete > 0 ? (
                                <div title={item.notCompletedBy} style={{ zIndex: '1' }}>
                                  <img src="/incompleteTraining.png" alt=""></img> {item.incomplete}
                                </div>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </div>
                          </div>
                        ))}
                        {!this.state.showMore && trainingCount > 3 ? (
                          <div style={{ borderTop: 'grey solid 1px' }}>
                            <button
                              className="loadMoreButton "
                              onClick={this.handleShowMore}
                              style={{
                                marginLeft: '146px',
                                fontSize: '12px',
                                fontFamily: 'nw-primary',
                                fontWeight: 'bold',
                                color: '#1c57a5',
                                marginTop: '10px',
                              }}
                            >
                              Load More
                            </button>
                          </div>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </React.Fragment>
                    ) : (
                      <div style={{ fontSize: '14px' }}>No training has been assigned</div>
                    )}
                  </div>
                  {/* <div className="blueLinkInternalContainer">
                    <button
                      className="tileButton fowDashboardButton"
                      onClick={() => this.goToFowDashboard()}
                    >
                      FOW Dashboard
                    </button>
                    <button
                      className="tileButton requiredTrainingButton"
                      onClick={() => this.goToTrainingReport()}
                    >
                      Required Training Report
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      //team has not been populated so display nothing
      return <React.Fragment></React.Fragment>;
    }
  }
  renderTeam() {
    //Update the Team Display
    const { team, teamMax } = this.props;
    let teamSize = 0;
    if (team) {
      //The team object has been populated
      if (team.error) {
        //the team object contains an error message
        return (
          <div className="fowContainer" style={{ height: '501px' }}>
            <div className="portletError">
              <div className="portletErrorAlert">!</div>
              <div
                style={{
                  fontFamily: 'nw-primary',
                  fontSize: '20px',
                  lineHeight: '24px',
                  color: '#4D4F53',
                }}
              >
                Oops, this is unexpected.
              </div>
              <div
                style={{
                  fontFamily: 'nw-primary',
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#4D4F53',
                }}
              >
                Error code: 500
                <br />
                An error has occurred and we're working to fix the issue.
                <br />
                If the issue persists please{' '}
                <a
                  style={{ color: '#4D4F53', textDecoration: 'underline' }}
                  href={config.contactUsUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  contact us.
                </a>
              </div>
              <div
                className="tile-apierror-refresh"
                // onMouseDown={() => this.getFow(index)}
                onClick={() => this.getTeam()}
                title="Refresh"
              >
                Refresh
              </div>
            </div>
          </div>
        );
      }

      // console.log("renderTeam team", team.members);
      const teamMembers = team.members;
      teamSize = teamMembers.length;
      if (teamSize === 0) {
        //There are no team members
        return (
          <React.Fragment>
            <div style={{ marginRight: '24px' }}>
              <div style={{ height: '39px', paddingLeft: '25px' }}> My Team (0)</div>
              <div id="my_team_members" className="scrollingTeam">
                <div className="donotshowfocus">
                  <div
                    style={{
                      textAlign: 'left',
                      paddingLeft: '25px',
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <div className="noTeamButton"></div>
                      <div style={{ display: 'inline-block', marginLeft: '24px' }}>
                        <div
                          style={{
                            fontSize: '18px',
                            color: '1c57a5',
                            fontWeight: 'bold',
                          }}
                        >
                          There is currently no information to display.
                        </div>
                        <div style={{ fontSize: '14px' }}>
                          If this is an error and the issue persists, please{' '}
                          <a
                            style={{
                              color: '#4D4F53',
                              textDecoration: 'underline',
                            }}
                            href={config.contactUsUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            contact us
                          </a>
                          .
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
      // console.log("myTeam..renderTeam isMobile", isMobile);
      const sliceAt = isMobile ? teamMax : teamSize;
      const members = teamMembers.slice(0, sliceAt);
      const display = this.state.display;
      // console.log("myTeam..renderTeam sliceAt", sliceAt);
      // console.log("myTeam..renderTeam teamSize", teamSize);
      return (
        <React.Fragment>
          <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
            <h2 className="teamsTitle">My Team ({teamSize})</h2>
            <div style={{ textAlign: 'right' }}>
              <button className="teamsExpand" onClick={e => this.handleTeamExpand(display)}>
                {display ? <div aria-label="Click to expand all team info"><p aria-hidden="true">Expand All</p></div> 
                : <div aria-label="Click to Collapse all team info"><p aria-hidden="true">Collapse All</p></div>}
              </button>
            </div>
          </div>
          <div id="my_team_members" className={isMobile ? 'noScrollingTeam' : 'scrollingTeam'}>
            {this.renderTeamProgress()}
            {members.map((item, index) => (
              <div className="donotshowfocus" key={'memberContainter' + index}>
                <div
                  style={
                    item.display
                      ? {
                          width: '95%',
                          textAlign: 'left',
                          // borderBottom: "black solid 1px",
                          paddingLeft: '25px',
                          backgroundImage: 'url(/activeTeam.png)',
                        }
                      : {
                          width: '95%',
                          textAlign: 'left',
                          // borderBottom: "black solid 1px",
                          paddingLeft: '25px',
                        }
                  }
                >
                  <div style={{ borderBottom: '#BCBDBC solid 1px' }}>
                    <div style={{ width: '100%' }} key={item.shortname + 'subdiv'}>
                      <button
                        tabIndex="0"
                        onClick={e => this.handleMemberClick(e, index)}
                        id={'member_' + index}
                        className="teamMemberButton"
                      >
                        <div
                          key={item.shortname + 'chevron'}
                          className="teamMemberChevronContainer"
                        >
                          <img
                            key={item.shortname + 'chevronImage'}
                            src={
                              item.display
                                ? '/icon-chevron-up-dark.svg'
                                : '/icon-chevron-down-dark.svg'
                            }
                            alt={item.display ? "Click to Collapse active team member" + item.associateName : "Click to Expand active team member" + item.associateName}
                          ></img>
                        </div>
                      </button>
                      <div
                        className="teamMemberImageContainer"
                        key={item.shortname + 'immagecontainer'}
                      >
                        <img
                          key={item.shortname + 'image'}
                          className="teamMemberImage"
                          alt={item.image ? 'Associate Image' : 'no-image'}
                          src={
                            item.image ? 'data:image/jpeg;base64,' + item.image : '/no-image.svg'
                          }
                        />
                      </div>
                      <div className="teamMemberData" key={item.shortname + 'memberData'}>
                        {item.associateName}
                        <div className="teamMemberPronouns" key={item.shortname}>
                        {item.learning.length != 0 ? (typeof item.learning[0].pronouns == 'string' ? item.learning[0].pronouns : "") : ""}
                        <div className="teamMemberPosition" key={item.shortname + 'memberPosition'}>
                          {item.position}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="teamDisplay"
                      style={
                        item.display
                          ? {
                              display: 'block',
                            }
                          : { display: 'none' }
                      }
                    >
                      <div className="teamMemberPhone">
                        OFFICE PHONE NUMBER:{' '}
                        <span className="teamMemberPhoneNumber">
                          {item.workPhone ? item.workPhone : 'Loading...'}
                        </span>
                      </div>
                      <div className="teamContactButtonContainer">
                        <button
                          className="btn emailButton"
                          onClick={() => this.setUpMeeting(item.userPrincipalName)}
                        >
                          Set up 1:1
                        </button>
                        <button
                          className="btn  chatButton"
                          onClick={() => this.openChat(item.userPrincipalName, 'chat')}
                        >
                          Chat
                        </button>

                        {/* <button
                        className="btn  chatButton"
                        onClick={() =>
                          this.openChat(item.userPrincipalName, "call")
                        }
                      >
                        Call
                      </button> */}
                        <button className="btn  memberDevPlan" onClick={() => this.openDevPlan()}>
                          Review development plan
                        </button>
                      </div>
                      {/* <div className="teamContactButtonContainer">
                      Quick Links
                      <button
                        className="btn  memberDevPlan"
                        onClick={() => this.openDevPlan()}
                      >
                        Review development plans
                      </button>
                      <div className="memberDevPlan">
                        <a
                          href="https://www.myworkday.com/nationwide/d/task/2998$11723.htmld"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Review development plans
                        </a>
                      </div>
                      <div className="memberTouchbase">
                        <a
                          href={
                            "https://teams.microsoft.com/l/meeting/new?subject=Touchbase%20Meeting&attendees=" +
                            item.userPrincipalName
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Set up a touchbase 1:1 meeting
                        </a>
                      </div>
                    </div> */}

                      {item.fow ? (
                        <div className="teamMemberFow">{this.renderFow(item.fow, index)}</div>
                      ) : (
                        <div
                          className="progress-spinner "
                          style={{
                            left: '50%',
                            top: '50%',
                            paddingTop: '15px',
                          }}
                        />
                      )}

                      <div className="teamMemberRequiredLearning">
                        {/*Enterprise Required Learning (restore text when needed)*/}
                        <div> 
                          <div>{/*console.log("this is the learning info ", item)*/}</div>
                        </div>
                        {item.learning ? (
                          <div>{this.renderLearning(item.learning)}</div>
                          
                        ) : (
                          <div
                            className="progress-spinner "
                            style={{
                              left: '50%',
                              top: '50%',
                              paddingTop: '15px',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <button
                className="btn btn-primary "
                onClick={e => this.handleLoadMore(e, teamSize)}
                style={
                  teamSize === teamMax || !isMobile
                    ? { display: 'none' }
                    : {
                        display: 'block',

                        marginLeft: '112px',
                      }
                }
              >
                Load More
              </button>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      //team has not been populated so display nothing
      return (
        <React.Fragment>
          <div style={{ height: '39px', paddingLeft: '25px' }}> My Team (0)</div>
          <div style={{ marginRight: '24px', paddingLeft: '25px' }}>
            <hr></hr>
          </div>
          <div id="my_team_members" className="scrollingTeam" style={{ paddingLeft: '25px' }}>
            {' '}
            <div
              className="progress-spinner "
              style={{ left: '50%', top: '50%', paddingTop: '15px' }}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  render() {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    var M = yesterday.getMonth() + 1;
    var D = yesterday.getDate();
    const YY = '' + yesterday.getFullYear();

    if (M < 10) {
      //Month is a single digit
      M = '0' + M;
    }
    if (D < 10) {
      //Day number is a single digit
      D = '0' + D;
    }
    const date = M + '/' + D + '/' + YY.substr(2, 2);
    return (
      <div
        className={isMobile ? 'mobileTeamContainer' : 'teamContainer'}
        style={{ backgroundImage: 'none' }}
      >
        <div className="teamContent" style={isMobile ? { overflow: 'hidden' } : {}}>
          <h2 className="team-tile-header">{this.renderTeam()}</h2>
          <div style={{ paddingLeft: '25px' }}>
            <div className="blueLinkContainer">
              <button
                className="tileButton fowDashboardButton"
                onClick={() => this.goToFowDashboard()}
              >
                FOW Dashboard
              </button>
              <button
                className="tileButton requiredTrainingButton"
                onClick={() => this.goToMyTeamLearning()}
              >
                My Team Learning
              </button>
            </div>
          </div>
        </div>
        <span
          className="tile-portletDate"
          style={{ fontFamily: 'nw-primary', fontStyle: 'normal' }}
        >
          As of {date}
        </span>
      </div>
    );
  }
}

export default MyTeam;
